var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "edit-role-permission-dialog",
      attrs: {
        title: "角色权限项设置",
        width: "510px",
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "set-role-permission" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.privilegeList,
                callback: function ($$v) {
                  _vm.privilegeList = $$v
                },
                expression: "privilegeList",
              },
            },
            [
              _vm._l(_vm.rolePermissionArr, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item.privilegeCode,
                    attrs: { label: item.privilegeCode },
                  },
                  [_vm._v(_vm._s(item.privilegeName))]
                )
              }),
              _vm.isCFuser && !_vm.isTsdnuser
                ? _vm._l(_vm.roleCFPermissionArr, function (item) {
                    return _c(
                      "el-checkbox",
                      {
                        key: item.privilegeCode,
                        attrs: { label: item.privilegeCode },
                      },
                      [_vm._v(_vm._s(item.privilegeName))]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.editLoading },
              on: { click: _vm.handleClose },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.editLoading },
              on: { click: _vm.confirmHandle },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }