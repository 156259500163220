<!-- role permission set wrapper -->
<template>
    <div class="tabs-wrapper">
        <div class="tab-bars">
            <div
                class="tab-bar"
                v-for="(it, index) in tabs"
                :key="'tab_' + index"
                :class="activeIndex === index ? 'active' : ''"
                @click="hanleClick(index)"
            >
                <span class="bar-title">{{ it.label }}</span>
            </div>
        </div>
        <div class="btns">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "lbd-tabs",
    components: {},
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        defaultActive: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            activeIndex: 0,
        };
    },
    // 生命周期 - DOM元素渲染前调用
    created() {},
    // 生命周期 - DOM元素渲染后调用
    mounted() {
        this.activeIndex = this.defaultActive;
    },
    // 计算属性（类似于data概念）
    computed: {},
    // 监听器（监控data中的数据变化）
    watch: {},
    // 方法集合
    methods: {
        hanleClick(index) {
            if (this.activeIndex === index) return;
            this.activeIndex = index;
            this.$emit("tab-click", index, this.tabs[index]);
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs-wrapper {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    .tab-bars {
        width: 60%;
        position: relative;
        font-size: 0;

        .tab-bar {
            // height: 60px;
            // line-height: 60px;
            // width: 110px;
            margin-left: 65px;
            display: inline-block;
            position: relative;
            user-select: none;
            text-align: center;
            margin-right: 3px;

            &.active {
                position: relative;
                .bar-title {
                    color: #38bc9c;
                    font-size: 16px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: -1px;
                    width: 50px;
                    height: 3px;
                    margin-left: -25px;
                    background-color: $primary;
                }
            }
            &:hover {
                cursor: pointer;
            }
        }
        .bar-title {
            display: inline-block;
            height: 52px;
            line-height: 52px;
            font-size: 14px;
        }

        .name-list-menu {
            display: none;
        }
    }
    .btns {
        width: 40%;
        text-align: right;
        padding-right: 20px;
        .el-button {
            margin-left: 10px;
        }
    }
}
</style>
