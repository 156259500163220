var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-permission-set" }, [
    _c(
      "div",
      { staticClass: "role-permission" },
      [
        _c("lbd-tabs", {
          attrs: { tabs: _vm.tabs, defaultActive: _vm.defaultActive },
          on: { "tab-click": _vm.handleTabClick },
        }),
        _vm.defaultActive === 1
          ? [
              _c("role-permission-set-table", {
                ref: "rolePermissionSetTable",
                attrs: { tableData: _vm.list },
                on: {
                  "edit-role-permission": _vm.handleEditRolePermission,
                  "add-role": _vm.handleAddRole,
                  "del-role": _vm.handleDelRole,
                },
              }),
              _vm.page.total > 20
                ? _c(
                    "div",
                    { staticClass: "work-table-footer" },
                    [
                      _c(
                        "el-pagination",
                        {
                          staticClass: "el-pagination-workTable",
                          attrs: {
                            "current-page": _vm.page.current,
                            "page-sizes": [20, 30, 50],
                            "page-size": _vm.page.size,
                            layout: "total, sizes, prev, pager, next, slot",
                            total: _vm.page.total,
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                          },
                        },
                        [
                          _c("span", { staticClass: "pagination-text" }, [
                            _c(
                              "span",
                              [
                                _vm._v("前往"),
                                _c("el-input", {
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.handlePagerJump.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.pagerJump,
                                    callback: function ($$v) {
                                      _vm.pagerJump = $$v
                                    },
                                    expression: "pagerJump",
                                  },
                                }),
                                _vm._v("页"),
                              ],
                              1
                            ),
                            _c("span", { on: { click: _vm.handlePagerJump } }, [
                              _vm._v("跳转"),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _vm.defaultActive === 0 ? [_c("members-role-set")] : _vm._e(),
        _c("edit-role-permission-dialog", {
          ref: "editRolePermissionDialog",
          on: { "update-role-permission": _vm.handleUpdateRolePermission },
        }),
        _c("role-dialog", {
          ref: "roleDialog",
          on: { "update-role": _vm.handleUpdateRole },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }