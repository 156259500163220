<template>
    <div class="user-permission-set">
        <div class="role-permission">

            <lbd-tabs
                :tabs="tabs"
                :defaultActive="defaultActive"
                @tab-click="handleTabClick"
                >
            </lbd-tabs>

            <template v-if="defaultActive === 1">
                <role-permission-set-table
                    ref="rolePermissionSetTable"
                    :tableData="list"
                    @edit-role-permission="handleEditRolePermission"
                    @add-role="handleAddRole"
                    @del-role="handleDelRole"
                ></role-permission-set-table>
                <!-- <table-page-tab
                    :filterIndex="1"
                    :pageTabs="pageTabJson"
                ></table-page-tab> -->

                <div class="work-table-footer" v-if="page.total>20">
                    <el-pagination
                        :current-page="page.current"
                        :page-sizes="[20, 30, 50]"
                        :page-size="page.size"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        class="el-pagination-workTable"
                        layout="total, sizes, prev, pager, next, slot"
                        :total="page.total"
                    >
                        <span class="pagination-text">
                            <span
                                >前往<el-input
                                    v-model="pagerJump"
                                    @keyup.enter.native="handlePagerJump"
                                ></el-input
                                >页</span
                            >
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </template>
            <template v-if="defaultActive === 0">
                <members-role-set></members-role-set>
            </template>
            <!-- <div class="work-table-footer" v-else></div> -->
            
            <edit-role-permission-dialog
                ref="editRolePermissionDialog" @update-role-permission="handleUpdateRolePermission"
            ></edit-role-permission-dialog>

            <role-dialog
                ref="roleDialog"
                @update-role="handleUpdateRole"
            ></role-dialog>
        </div>
    </div>
</template>

<script>
import RolePermissionSetTable from "./layout/role-permission-set-table.vue";
import EditRolePermissionDialog from "./components/edit-role-permission-dialog.vue";
import RoleDialog from "./components/role-dialog.vue";
import LbdTabs from "./components/lbd-tabs.vue";
import membersRoleSet from "./membersRoleSet.vue";
import TablePageTab from "#/component/workTable/table-page-tab.vue";
import pageTabJson from "./config/pageTab.json";
import UserPermissionService from '#/js/service/userPermissionService.js';
import { 
    getAuthRolePrivilegeList,
    authRoleDelete,
 } from "#/js/service/commonService.js";

export default {
    name: "RolePermissionSet",
    components: {
        TablePageTab,
        RolePermissionSetTable,
        EditRolePermissionDialog,
        RoleDialog,
        LbdTabs,
        membersRoleSet,
    },
    data() {
        return {
            tabs: [
                { label: "成员角色设置", name: "first", },
                { label: "角色权限设置", name: "second", }
            ],
            defaultActive: 1,

            loading: false,
            list: [],
            page: {
                total: 4,
                current: 1,
                size: 20,
            },
            pagerJump: 0,
        };
    },
    computed: {
        pageTabJson() {
            if (this.$store.state.user.userInfo.isCFUser) {
                return pageTabJson.CFUser;
            } else {
                return pageTabJson.notCFUser;
            }
        },
        isHuntingCompanySetPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('UserPermissionSet');
        },
    },
    mounted() {
        if(this.$store.state.user.userInfo.isAdministrator || this.isHuntingCompanySetPermission) {
            this.loading = false;
            this.getList();
        } else {
            shortTips("权限不足，请联系管理员");
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            let _index = tabItems.findIndex(
                (item) => item.path === "/rolePermissionSet"
            );
            tabItems.splice(_index, 1);
            sessionStorage.setItem("tabItems", JSON.stringify(tabItems));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000);
        }
    },
    methods: {
        handleTabClick(idx) {
            this.defaultActive = idx;
        },

        // 点击分页之后，让数据定位到第一条
        setScrollTop(){
            // console.log(document.getElementsByClassName('el-table__body-wrapper'))
            // console.log(document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop)
            document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
        },
        // 分页
        handleSizeChange(val) {
            this.page.current = 1;
            this.page.size = val;
            this.getList();
            this.setScrollTop();
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getList(true);
            this.setScrollTop();
        },
        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total / this.page.size);
            if (currentPager > 0 && currentPager <= currentPageTotal) {
                this.page.current = currentPager;
                this.getList(true);
                this.setScrollTop();
            }
        },
        getList(isSilent = false) {
            let params = Object.assign({}, this.params);
            if(!isSilent) {
                this.page.current = 1;
            }
            params.pageSize = this.page.size;
            params.offset = (this.page.current - 1) * this.page.size;
            this.$refs.rolePermissionSetTable.loading = true;

            // UserPermissionService.getRolePrivilegeList(params).then(res => {
            //     this.$refs.rolePermissionSetTable.loading = false;
            //     this.page.total = res.count;
            //     // console.log(res)
            //     this.list = res;
            // }).catch(err => {
            //     this.$refs.rolePermissionSetTable.loading = false;
            // });

            getAuthRolePrivilegeList(params)
                .then(res => {
                    this.page.total = res.count;
                    this.list = res;
                })
                .finally(() => {
                    this.$refs.rolePermissionSetTable.loading = false;
                });
        },

        handleEditRolePermission(index, item) {
            // this.$refs.editRolePermissionDialog.show({
            //     index: index,
            //     data: item,
            // });
            this.$refs.roleDialog.show('edit', {
                index: index,
                data: item,
            });
        },
        // 编辑成功后
        handleUpdateRolePermission(){
            // 编辑完成之后重新刷新列表
            this.getList();
        },

        handleAddRole() {
            this.$refs.roleDialog.show('add');
        },

        handleDelRole(index, row) {
            this.$alert('确定删除当前角色吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if( 'confirm' === action) {
                        authRoleDelete({ roleId: row.roleId })
                            .then(res =>  {
                                shortTips(`删除成功`);
                                this.getList();
                            })
                            .catch(err => {
                                const {errorMessage} = err;
                                if(errorMessage.includes(`该角色`)) {
                                    this.delFailTip();
                                }
                            });
                    }
                }
            });
                            
                           
        },

        delFailTip() {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, [
                    h('p', { style: 'color: #333;font-size: 14px;' }, '无法删除该角色 '),
                    h('p', { style: 'color: #999;' }, '当前有用户鄉定在该角色下，无法删除该角色。'),
                    h('p', { style: 'color: #999;' }, '你可以在角色成员设置中筛选该角色，查看綁定的用户'),
                    ]),
                showCancelButton: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(action => {
                // this.$message({
                //     type: 'info',
                //     message: 'action: ' + action
                // });

                // authRoleDelete
            });
        },
 

        handleUpdateRole() {
            this.getList();
        },
    },
};
</script>

<style lang="scss" scope>
.user-permission-set {
    height: 100%;
    // background: blue;
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
    
    .role-permission {
        height: 100%;
        min-height: 500px;
        // padding: 20px 0;
        border-radius: 8px;
        // background: white url("~@src/assets/images/home/home_bg.png") bottom center no-repeat;
        background: white;
        display: flex;
        flex-direction: column;
        .work-table-footer {
            padding: 0 20px;
            margin-top: 20px;
            height: 28px;
            overflow: unset;
        }
    }
}
</style>