<template>
    <el-dialog
        title="角色权限项设置"
        width="510px"
        :visible.sync="dialogVisible"
        @close="handleClose"
        v-loading="loading"
        class="edit-role-permission-dialog"
    >
        <div class="set-role-permission">
            <el-checkbox-group v-model="privilegeList">
                <el-checkbox
                    v-for="item in rolePermissionArr"
                    :key="item.privilegeCode"
                    :label="item.privilegeCode"
                    >{{ item.privilegeName }}</el-checkbox
                >
                <template v-if="isCFuser && !isTsdnuser">
                    <el-checkbox
                        v-for="item in roleCFPermissionArr"
                        :key="item.privilegeCode"
                        :label="item.privilegeCode"
                        >{{ item.privilegeName }}</el-checkbox
                    >
                </template>
            </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button :loading="editLoading" @click="handleClose"
                >取消</el-button
            >
            <el-button
                type="primary"
                :loading="editLoading"
                @click="confirmHandle"
                >确定</el-button
            >
        </span>
    </el-dialog>
</template>

<script>
import UserPermissionService from "#/js/service/userPermissionService.js";
export default {
    data() {
        return {
            dialogVisible: false,
            loading: false,
            editLoading: false,
            privilegeList: [],
            rolePermissionArr: [
                {
                    privilegeName: "业务",
                    privilegeCode: "Business",
                },
                {
                    // privilegeName: "数据",
                    privilegeName: "有限团队数据",
                    privilegeCode: "Data",
                },
                {
                    privilegeName: "成员",
                    privilegeCode: "Member",
                },
                {
                    privilegeName: "财务",
                    privilegeCode: "Financial",
                },
                {
                    privilegeName: "猎企设置",
                    privilegeCode: "HuntingCompanySet",
                },
                {
                    privilegeName: "Offer列表",
                    privilegeCode: "OfferList",
                },
                {
                    privilegeName: "客户管理",
                    privilegeCode: "CustomerManage",
                },
                {
                    privilegeName: "平台运营",
                    privilegeCode: "PlatformOperations",
                },
                {
                    privilegeName: "团队数据",
                    privilegeCode: "TeamData",
                },
            ],
            roleCFPermissionArr: [
                // {
                //     privilegeName: "团队数据",
                //     privilegeCode: "TeamData",
                // },
                {
                    privilegeName: "大厂机会管理",
                    privilegeCode: "CompanyOpportunityManage",
                },
            ],
            switchOrguditor: 0,
            switchGenCoordinator: 0,
            index: 0,
            data: {},
        };
    },
    watch: {
        // privilegeList(val){
        //     console.log(val)
        // }
    },
    computed: {
        isCFuser(){
            return this.$store.state.user.userInfo.isCFUser;
        }, 
        isTsdnuser(){
            return this.$store.state.user.userInfo.tsdnuser;
        }, 
    },
    mounted() {},
    methods: {
        show({ index, data } = {}) {
            // console.log(index);
            // console.log(data);
            this.index = index;
            this.data = data;
            // this.privilegeList = this.data.privilegeList;
            // console.log(this.data.privilegeList)
            // this.privilegeList = this.data.privilegeList.map(item=>{
            //     return item.privilegeCode
            // })
            // 如果this.data.privilegeList是数组对象，里面有privilegeCode和privilegeName，提取出privilegeCode就行
            if (
                this.data.privilegeList.length > 0 &&
                this.data.privilegeList[0].privilegeCode
            ) {
                this.privilegeList = this.data.privilegeList.map(
                    (item) => {
                        return item.privilegeCode;
                    }
                );
            } else { // this.data.privilegeList是['Business', 'HuntingCompanySet', 'Data']这种格式的时候
                this.privilegeList = this.data.privilegeList;
            }
            // console.log(this.privilegeList);
            this.dialogVisible = true;
        },
        // 关闭编辑弹窗
        handleClose() {
            this.privilegeList = [];
            this.dialogVisible = false;
        },
        // 点击确定按钮，提交
        confirmHandle() {
            // console.log(this.data)
            // console.log(this.privilegeList)
            this.editLoading = true;
            let RolePrivilegeEditRequest = {
                privilegeCodeList: this.privilegeList,
                roleCode: this.data.roleCode
            };
            // console.log(RolePrivilegeEditRequest)
            UserPermissionService.editRolePrivilege(RolePrivilegeEditRequest).then((res) => {
                    this.editLoading = false;
                    shortTips("修改成功");
                    // 修改完成之后重新刷新列表
                    this.$emit("update-role-permission");
                    this.dialogVisible = false;
                    // 当前登录用户角色权限变更，刷新页面
                    if(this.data.userId == this.$store.state.user.userInfo.id) {
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    this.editLoading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.edit-role-permission-dialog {
    .el-dialog__body {
        padding: 0;
        .el-checkbox-group .el-checkbox .el-checkbox__label {
            color: #38bc9d;
        }
        .el-checkbox-group .el-checkbox.is-checked .el-checkbox__inner {
            border-color: #38bc9d;
            background: #38bc9d;
            &::after {
                border-color: #fff;
            }
        }
    }
    .set-role-permission {
        padding: 30px 22px 38px;
        .el-checkbox-group{
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            // align-content: flex-start;
            .el-checkbox{
                width: 25%;
                margin: 0;
                margin-bottom: 4px;
            }
        }
        
    }
}
</style>
