<template>
    <div class="role-permission-set-table">
        <div class="top-row">
            <el-button type="primary" @click="addRole">添加角色</el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            height="700"
            tooltip-effect="light"
        >
            <!-- <el-table-column
                prop="roleCode"
                align="center"
                :show-overflow-tooltip="true"
                label="角色"
            >
                <template slot-scope="scope">
                    <i v-if="scope.row.roleCode == 'HeadHunters'">猎头</i>
                    <i v-if="scope.row.roleCode == 'Financial'">财务</i>
                    <i v-if="scope.row.roleCode == 'Executive'">行政</i>
                    <i v-if="scope.row.roleCode == 'InternalControl'">内控</i>
                </template>
            </el-table-column> -->
            <!-- <el-table-column
                prop="privilegeList "
                align="center"
                :show-overflow-tooltip="true"
                label="角色权限"
            >
                <template slot-scope="scope">
                    <span
                        v-for="(item, index) in scope.row.privilegeList"
                        :key="index"
                    >
                        <i v-if="index != '0'"> / </i>
                        <span>
                            <i v-if="item == 'Business'">业务</i>
                            <i v-if="item == 'Data'">数据</i>
                            <i v-if="item == 'Member'">成员</i>
                            <i v-if="item == 'Financial'">财务</i>
                            <i v-if="item == 'HuntingCompanySet'">猎企设置</i>
                        </span>
                    </span>
                </template>
            </el-table-column> 
            min-width="160px"
            -->
            <el-table-column
                prop="roleName"
                align="center"
                :show-overflow-tooltip="true"
                label="角色"
                width="320px"
            >
            </el-table-column>
            <!-- :show-overflow-tooltip="true" -->
            <el-table-column
                prop="privilegeList "
                align="left"
                label="角色权限"
                min-width="500px"
            >
                <template slot-scope="{row}">
                    <!-- <span
                        v-for="(item, index) in row.privilegeList"
                        :key="index"
                    >
                        <i v-if="index != '0'"> / </i>
                        <i >{{item.privilegeName}}</i>
                    </span> -->
                    <el-row v-if="BusinessGroup(row).length>0">业务：
                        <el-tag 
                            size="small" 
                            class="tag-cell"
                            v-for="(it,idx) in BusinessGroup(row)"
                            :key="'BusinessGroup_' + idx"
                            >{{it.privilegeName}}
                        </el-tag>
                    </el-row>

                    <el-row v-if="DataGroup(row).length>0">数据：
                        <el-tag 
                            size="small" 
                            class="tag-cell"
                            v-for="(it,idx) in DataGroup(row)"
                            :key="'DataGroup_' + idx"
                            >{{it.privilegeName}}
                        </el-tag>
                    </el-row>

                    <el-row v-if="HuntingCompanySetGroup(row).length>0">猎企设置：
                        <el-tag 
                            size="small" 
                            class="tag-cell"
                            v-for="(it,idx) in HuntingCompanySetGroup(row)"
                            :key="'HuntingCompanySetGroup_' + idx"
                            >{{it.privilegeName}}
                        </el-tag>
                    </el-row>

                    <el-row v-if="OthersGroup(row).length>0">其他：
                        <el-tag 
                            size="small" 
                            class="tag-cell"
                            v-for="(it,idx) in OthersGroup(row)"
                            :key="'OthersGroup_' + idx"
                            >{{it.privilegeName}}
                        </el-tag>
                    </el-row>
                </template>
            </el-table-column>
             <!-- <span v-if="item.privilegeName">{{ item.privilegeName }}</span> -->
                        <!-- <span v-if="item.privilegeName">{{ item.privilegeName == '数据' ? '有限团队数据' : item.privilegeName }}</span>
                        <span v-else>
                            <i v-if="item == 'Business'">业务</i> -->
                            <!-- <i v-if="item == 'Data'">数据</i> -->
                            <!-- <i v-if="item == 'Data'">有限团队数据</i>
                            <i v-if="item == 'Member'">成员</i>
                            <i v-if="item == 'Financial'">财务</i>
                            <i v-if="item == 'HuntingCompanySet'">猎企设置</i>
                            <i v-if="item == 'OfferList'">Offer列表</i>
                            <i v-if="item == 'CustomerManage'">客户管理</i>
                            <i v-if="item == 'PlatformOperations'">平台运营</i>
                            <i v-if="item == 'TeamData'">团队数据</i> 
                        </span>-->
            <el-table-column align="center" label="操作" width="160px">
                <template slot-scope="{$index, row}">
                    <span
                        class="editText"
                        @click="handleEdit($index, row)"
                        >编辑
                    </span>
                    <span
                        class="delText"
                        v-if="defaultRole.indexOf(row.roleCode) == -1"
                        @click="handleDel($index, row)"
                        >删除
                    </span>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            defaultRole: ["InternalControl","Financial","PlatformOperationer","HeadHunters"],
            // tableData: [
            //     {
            //         roleCode: "HeadHunters",
            //         privilegeList: ["Business", "Data", "Member", "Financial", "HuntingCompanySet"],
            //     },
            //     {
            //         roleCode: "Financial",
            //         privilegeList: ["Business", "Data", "Member", "Financial", "HuntingCompanySet"],
            //     },
            //     {
            //         roleCode: "Executive",
            //         privilegeList: ["Business", "Data", "Member", "Financial", "HuntingCompanySet"],
            //     },
            //     {
            //         roleCode: "InternalControl",
            //         privilegeList: ["Business", "Data", "Member", "Financial", "HuntingCompanySet"],
            //     },
            // ],
            privilegeCodeMap: {
                Business: ['Project', 'Talent', 'Market', 'Customer', 'Job', 'AgreementJob', 'CompanyOpportunity', 'AgreementJobManage'],
                Data: ['TrackData', 'TeamData', 'PersonalData', 'LimitedTeamData', ],
                HuntingCompanySet: ['BusinessClassifySet', 'ResumeSet', 'FirmSet', 'WeComSet', 'JobCategorySet', 'PointSet', 'AuthorizationSet', 'Member', 'UserPermissionSet', 'PerformanceSet', 'CompanyOpportunityAuditSet', 'OfferBroadcastSet'],
                Others: ['DepartmentManageAudit', 'BusinessCoordinator', 'Financial', 'DepartmentReviewer', 'PlatformOperations', 'GeneralCoordinator', 'OfferList', 'CustomerCreateAudit', 'CustomerContractView', 'CustomerContractManage', ],
            },
        };
    },
    methods: {
        handleEdit(index, row) {
            this.$emit("edit-role-permission", index, row);
        },

        addRole() {
            this.$emit("add-role");
        },

        handleDel(index, row) {
            this.$emit("del-role", index, row);
        },

        BusinessGroup(row) {
            return row.privilegeList.filter(it => this.privilegeCodeMap.Business.includes(it.privilegeCode)) || [];
        },
        DataGroup(row) {
            return row.privilegeList.filter(it => this.privilegeCodeMap.Data.includes(it.privilegeCode)) || [];
        },
        HuntingCompanySetGroup(row) {
            return row.privilegeList.filter(it => this.privilegeCodeMap.HuntingCompanySet.includes(it.privilegeCode)) || [];
        },
        OthersGroup(row) {
            return row.privilegeList.filter(it => this.privilegeCodeMap.Others.includes(it.privilegeCode)) || [];
        },
    },
};
</script>

<style lang="scss" scope>
.role-permission-set-table {
    height: 60.6% !important;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    
    background: white;
    .top-row{
        width: 100%;
        text-align: right;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .editText {
        cursor: pointer;
        color: $primary;
    }
    .delText{
        cursor: pointer;
        color: $orange;
        margin-left: 10px;
    }
    .tag-cell{
        margin: 4px 6px;
        background-color: white;
    }
    
    /deep/ .el-table {
        background-color: transparent;
        .el-table__body-wrapper {
            background-color: #fff;
        }
        th {
            padding: 0;
            height: 42px;
            line-height: 42px;
            background-color: #e8e8e8;
            border-color: #ccc;
            color: #666;
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg")
                    no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>