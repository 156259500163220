<template>
    <el-dialog
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        class="role-dialog"
        width="818px"
    >
        <div class="tit" slot="title">{{ isEdit ? "编辑" : "添加" }}角色</div>

        <div :loading="loading" class="wrap" id="role_dialog_wrap">
            <div class="role-name">
                <span class="role-label">角色名称</span>
                <el-input
                    class="name"
                    maxlength="20"
                    placeholder="请输入要创建角色名称"
                    v-model.trim="roleName"
                    :disabled="isDefaultRole"
                ></el-input>
            </div>
            <div :key="'b_' + index" class="it-wrap" v-for="(bItem, index) in rolePermissionMap">
                <div class="lab">{{ bItem.name }}</div>

                <el-checkbox-group
                    v-if="bItem.subPrivilegesList && bItem.subPrivilegesList.length > 0"
                    v-model="checkedList[bItem.code]"
                >
                    <el-checkbox
                        :key="'s_' + index + sIndex"
                        :label="sItem.code"
                        v-for="(sItem, sIndex) in bItem.subPrivilegesList"
                        >{{ sItem.name }}</el-checkbox
                    >
                </el-checkbox-group>
                <p v-else>暂无数据</p>
            </div>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm" type="primary" :loading="confirmLoading"
                >保 存</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
// import { rolePermissionMap } from "../config/role-dialog-data.js";
import {
    getAuthPrivilegeList,
    authRolePrivilegeEdit,
    authRoleAdd,
} from "#/js/service/commonService.js";

export default {
    name: "role-dialog",
    components: {},
    data() {
        return {
            dialogVisible: false,
            checkedList: {},
            originCheckedList: {},
            loading: false,
            rolePermissionMap: [],
            roleName: "",
            roleCode: "",

            isEdit: false,
            defaultRole: ["InternalControl", "Financial", "PlatformOperationer", "HeadHunters"],
            isDefaultRole: false,
            confirmLoading: false,
        };
    },
    created() {},
    mounted() {
        this.fetchAuthPrivilegeList();
    },
    methods: {
        show(type = "add", params) {
            console.log("🚀 ~ show ~ params:", params);
            this.checkedList = JSON.parse(JSON.stringify(this.originCheckedList));
            this.dialogVisible = true;
            if (type === "edit") {
                this.isEdit = true;
                const {data, index} = params;
                console.log(this.rolePermissionMap);
                this.getEditCheckedResult(data);
                this.roleName = data.roleName;
                this.roleCode = data.roleCode;

                if (this.defaultRole.includes(data.roleCode)) {
                    this.isDefaultRole = true;
                } else {
                    this.isDefaultRole = false;
                }
            } else {
                this.isEdit = false;
                this.roleName = "";
                this.roleCode = "";
                this.checkedList = JSON.parse(JSON.stringify(this.originCheckedList));
                this.isDefaultRole = false;
            }
        },

        // handleConfirm() {
        //   if (!this.roleName) {
        //     this.$message.error("请输入角色名称");
        //     return;
        //   }

        //   if (this.isEdit) {
        //     const { roleCode } = this;
        //     const privilegeList = [];
        //   } else {
        //     this.isEdit = false;
        //     this.roleName = "";
        //     this.roleCode = "";
        //     // this.checkedList = this.originCheckedList;
        //     // this.form = this.$options.data().form;
        //   }

        //   this.$nextTick(() => {
        //     document.querySelector("#role_dialog_wrap").scrollTop = 0;
        //   });
        // },

        getEditCheckedResult(data) {
            const {privilegeList} = data;
            const flatPrivilegeList = this.rolePermissionMap.reduce((pre, cur) => {
                let temp = [];
                if (cur.subPrivilegesList) {
                    temp = cur.subPrivilegesList.map((it) => ({
                        ...it,
                        parentCode: cur.code,
                    }));
                }

                return pre.concat(temp);
            }, []);

            privilegeList.forEach((it, index) => {
                flatPrivilegeList.find((item) => {
                    if (item.code === it.privilegeCode) {
                        this.checkedList[item.parentCode].push(it.privilegeCode);
                    }
                });
            });
        },

        fetchAuthPrivilegeList() {
            this.loading = true;

            getAuthPrivilegeList()
                .then((res) => {
                    this.rolePermissionMap = res.subPrivilegesList || [];
                    let temp = {};
                    this.rolePermissionMap.forEach((it) => {
                        temp[it.code] = [];
                    });

                    this.checkedList = temp;
                    this.originCheckedList = JSON.parse(JSON.stringify(temp));
                })
                .catch((err) => {})
                .finally(() => {
                    this.loading = false;
                });
        },

        handleClose(done) {
            done();
            //   this.$confirm("确认关闭？")
            //     .then((_) => {
            //       done();
            //     })
            //     .catch((_) => {});
        },

        privilegeCode2Id() {
            const rolesMap = this.rolePermissionMap.reduce((pre, cur) => {
                let temp = [];
                if (cur.subPrivilegesList) {
                    temp = cur.subPrivilegesList;
                }
                return pre.concat(temp);
            }, []);
            let result = [];
            Object.keys(this.checkedList).forEach((it) => {
                this.checkedList[it].forEach((item) => {
                    rolesMap.find((it) => {
                        if (it.code === item) {
                            result.push(it.id);
                        }
                    });
                });
            });
            return result;
        },
        privilegeCodeArr() {
            let result = [];
            Object.keys(this.checkedList).forEach((it) => {
                if (this.checkedList[it] && this.checkedList[it].length > 0) {
                    result.concat(this.checkedList[it]);
                }
            });
            return result;
        },

        combinedArray() {
            return Object.values(this.checkedList).flat();
        },

        handleConfirm() {
            if (this.roleName === "") {
                this.$message.error("请输入角色名称");
                return;
            }

            let params = {
                name: this.roleName,
                privilegeCodeList: this.combinedArray(),
            };

            this.confirmLoading = true;
            if (this.isEdit) {
                params.roleCode = this.roleCode;
                authRolePrivilegeEdit(params)
                    .then((res) => {
                        this.$message.success("修改成功");
                        this.$emit("update-role");
                    })
                    .catch((err) => {})
                    .finally(() => {
                        this.confirmLoading = false;
                        this.dialogVisible = false;
                    });
            } else {
                authRoleAdd(params)
                    .then((res) => {
                        this.$message.success("添加成功");
                        this.$emit("update-role");
                    })
                    .catch((err) => {})
                    .finally(() => {
                        this.confirmLoading = false;
                        this.dialogVisible = false;
                    });
            }
            //   this.dialogVisible = true;
        },
    },
};
</script>

<style lang="scss" scope>
.role-dialog {
    .tit {
        font-size: 16px;
        font-weight: 600;
        line-height: 50px;
        text-align: left;
        color: #333;
    }

    .wrap {
        max-height: 420px;
        height: 420px;
        overflow-y: auto;

        .role-name {
            width: 55%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            //   margin-bottom: 20px;
            .role-label {
                font-size: 16px;
                font-weight: 600;
                margin-right: 10px;
                color: #333;
            }

            .name {
                flex: 1;
                max-width: 296px;
                width: 296px;
            }
        }

        .it-wrap {
            .lab {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 2px;
                color: #333;
                padding: 10px 0;
            }
        }
    }

    .dialog-footer {
        text-align: center;
    }
}
</style>
