var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "role-dialog",
      attrs: {
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.dialogVisible,
        width: "818px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tit", attrs: { slot: "title" }, slot: "title" },
        [_vm._v(_vm._s(_vm.isEdit ? "编辑" : "添加") + "角色")]
      ),
      _c(
        "div",
        {
          staticClass: "wrap",
          attrs: { loading: _vm.loading, id: "role_dialog_wrap" },
        },
        [
          _c(
            "div",
            { staticClass: "role-name" },
            [
              _c("span", { staticClass: "role-label" }, [_vm._v("角色名称")]),
              _c("el-input", {
                staticClass: "name",
                attrs: {
                  maxlength: "20",
                  placeholder: "请输入要创建角色名称",
                  disabled: _vm.isDefaultRole,
                },
                model: {
                  value: _vm.roleName,
                  callback: function ($$v) {
                    _vm.roleName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "roleName",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.rolePermissionMap, function (bItem, index) {
            return _c(
              "div",
              { key: "b_" + index, staticClass: "it-wrap" },
              [
                _c("div", { staticClass: "lab" }, [_vm._v(_vm._s(bItem.name))]),
                bItem.subPrivilegesList && bItem.subPrivilegesList.length > 0
                  ? _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.checkedList[bItem.code],
                          callback: function ($$v) {
                            _vm.$set(_vm.checkedList, bItem.code, $$v)
                          },
                          expression: "checkedList[bItem.code]",
                        },
                      },
                      _vm._l(bItem.subPrivilegesList, function (sItem, sIndex) {
                        return _c(
                          "el-checkbox",
                          {
                            key: "s_" + index + sIndex,
                            attrs: { label: sItem.code },
                          },
                          [_vm._v(_vm._s(sItem.name))]
                        )
                      }),
                      1
                    )
                  : _c("p", [_vm._v("暂无数据")]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("保 存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }