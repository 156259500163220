var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs-wrapper" }, [
    _c(
      "div",
      { staticClass: "tab-bars" },
      _vm._l(_vm.tabs, function (it, index) {
        return _c(
          "div",
          {
            key: "tab_" + index,
            staticClass: "tab-bar",
            class: _vm.activeIndex === index ? "active" : "",
            on: {
              click: function ($event) {
                return _vm.hanleClick(index)
              },
            },
          },
          [_c("span", { staticClass: "bar-title" }, [_vm._v(_vm._s(it.label))])]
        )
      }),
      0
    ),
    _c("div", { staticClass: "btns" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }